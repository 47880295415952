.agreement {
    text-align: center;
    padding: 20px 20px 20px 20px;
}

.agreementCollect {
    text-align: right;
    font-size: 30px;
    font-weight: 400;
    color: #1F252A; 
    padding-right: 90px;
}

.AgreementCard {
    width: 600px;
    background: rgba(83, 132, 128, 0.3);
    border-radius: 15px;
    border: solid;
    border-color: rgba(83, 132, 128, 0.2);
    margin-left: 90px;
    margin-top: 20px;
    padding-bottom: 12px;
}

.donut{
    width: 350px;
    height: 350px;
    float: right;
    margin-right: 90px;
    margin-top: 20px;
}

.buttonGroupAgreement{
    padding-top: 10px;
    text-align: center;
}

.cardAgreement{
    margin: auto;
}

.text-center{
    font-weight: 400;
}

.cardTextAgreement{
    color: hsl(207, 15%, 14%);
    font-weight: 400;
    font-size: 25px;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .agreementCollect{
        font-size: 25px;
        text-align: center;
        margin: 0;
        padding: 0;
    }
    .AgreementCard{
        margin: 0;
    }
    .donut{
        margin: 0;
        padding: 0;
        margin-top: 20px;
    }
}