.letterButton{
    text-align: center;
}

.cityQuantity{
    margin: 40px;
    text-align: left;
}

.state{
    padding: 0;
}

/* .letterButton{
    background: #459788;
    border: none;
} */

@media screen and (max-width: 600px) {
    /* .col{
        width: 50%;
    }
    .col-md-3{
        width: 50%;
    } */
}