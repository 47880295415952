@font-face {
    font-family: "Fira Sans";
    src: url('../../fonts/sinkinSans/SinkinSans-700Bold.otf') format('otf');
    src: url('../../fonts/sinkinSans/SinkinSans-500Medium.otf') format('otf');
}

.avaregeIncome{
    background-color: #ffffff;
    width: 100%;
    max-width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    margin-left: 0%;
    margin-top: 291.1%;
    background-size:contain;
    z-index: 0;
}

.textIncome {
    color: #00594f;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 2.5vw;
    font-weight: 700;
    letter-spacing: 0.2vw;
    line-height: 110%;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 7.5%;
    margin-top: 5%;
    z-index: 1;
}

.textIncomeMap {
    color: #00594f;
    font-family: "Sinkin Sans-500Medium", Helvetica;
    font-size: 1.1vw;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 120%;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 7.5%;
    margin-top: 15%;
    z-index: 1;
}

.mapBox4 {
    background-color: #ffffff;
    width: 55%;
    max-width: 80%;
    position: absolute;
    top: 0; 
    left: 0;
    padding-left: 31%;
    margin-top: 6%;
    z-index: 0;
}

.textMap4 {
    background-color: #ffffff;
    color: #00594f;
    position: absolute;
    font-family: "Sinkin Sans-500Medium", Helvetica;
    font-size: 0.8vw;
    font-weight: 500;
    top: 0;
    margin-top: 8%;
    left: 0;
    margin-left: 57%;
    z-index: 1;
}

.textIncomeBrasil {
    color: #00594f;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 2.5vw;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 7.5%;
    margin-top: 25%;
    z-index: 1;
}

.textIncomeBrasil2 {
    color: #00594f;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 4vw;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 7.5%;
    margin-top: 28%;
    z-index: 1;
}

.textIncomeBrasil3 {
    color: #00594f;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 3vw;
    font-weight: 700;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 75%;
    margin-top: 18%;
    z-index: 1;
}

.invoiceStateText {
    font-family: "Sinkin Sans-500Medium", Helvetica;
    font-size: 1.2vw;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.13vw;
    color: #00594f;
    position: absolute;
    margin-left: 75%;
    margin-top: 23%;
    float: left;
    z-index: 1;
}

.rectangle7Smaller {
    width: 21%;
    max-width: 100%;
    height:auto;
    left: 0;
    margin-left: 73.8%;
    position: absolute;
    top: 0;
    margin-top: 21%;
    z-index: 1;
}

.avaregeIncomeText{
    text-align: right;
    font-size: 30px;
    font-weight: 400;
    color: #1F252A;
    padding-right: 90px;
    float: right;
}

.cardGroupeAvarege{
    justify-content: center;
    text-align: center;
    padding-bottom: 30px;
}

.cardavAregeIndex{
    color: #1F252A; 
    background: #A3EADD;
    margin-right: 30px;
    text-align: center;
}

.cardAvarege{
    color: #1F252A; 
    margin-right: 30px !important;
    padding: 0;
    margin: 0;
}

.avaregeTitle{
    background: #A3EADD;
    font-weight: 300;
    font-size: 27px;
    color: #1F252A; 
}

.cardAvarege p{
    text-align: center;
    float: none;
}

.imgSection5{
    /* position: absolute; */
    /* padding-right: -11 !important; */
    /* padding-top: 200px; */
    margin-left: -31px;
}


/*Mobile Vertical 1*/
@media only screen and (min-width: 412px)  and (max-width: 433px) and (min-height: 805px) and (max-height: 915px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 25%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Mobile Vertical 2*/
@media only screen and (min-width: 414px)  and (max-width: 435px) and (min-height: 788px) and (max-height: 896px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 26%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Mobile Vertical 3*/
@media only screen and (min-width: 393px)  and (max-width: 413px) and (min-height: 768px) and (max-height: 873px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 25%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Mobile Vertical 4*/
@media only screen and (min-width: 412px)  and (max-width: 433px) and (min-height: 784px) and (max-height: 892px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 26%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Mobile Vertical 5*/
@media only screen and (min-width: 360px)  and (max-width: 378px) and (min-height: 563px) and (max-height: 640px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 32%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Mobile Vertical 6*/
@media only screen and (min-width: 360px)  and (max-width: 378px) and (min-height: 704px) and (max-height: 800px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 25%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Mobile Vertical 7*/
@media only screen and (min-width: 385px)  and (max-width: 405px) and (min-height: 751px) and (max-height: 854px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 25%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Mobile Vertical 8*/
@media only screen and (min-width: 393px)  and (max-width: 413px) and (min-height: 748px) and (max-height: 851px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 26%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Mobile Vertical 9*/
@media only screen and (min-width: 412px)  and (max-width: 433px) and (min-height: 764px) and (max-height: 869px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 27%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Mobile Vertical 10*/
@media only screen and (min-width: 390px)  and (max-width: 410px) and (min-height: 742px) and (max-height: 844px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 26%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Mobile Vertical 11*/
@media only screen and (min-width: 360px)  and (max-width: 378px) and (min-height: 668px) and (max-height: 760px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 27%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Mobile Vertical 12*/
@media only screen and (min-width: 375px)  and (max-width: 394px) and (min-height: 586px) and (max-height: 667px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 31%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Mobile Vertical 13*/
@media only screen and (min-width: 384px)  and (max-width: 404px) and (min-height: 751px) and (max-height: 854px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 25%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Tablet Vertical 1*/
@media only screen and (min-width: 768px)  and (max-width: 807px) and (min-height: 901px) and (max-height: 1024px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 41%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Tablet Vertical 2*/
@media only screen and (min-width: 601px)  and (max-width: 632px) and (min-height: 846px) and (max-height: 962px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 35%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Tablet Vertical 3*/
@media only screen and (min-width: 800px)  and (max-width: 840px) and (min-height: 1126px) and (max-height: 1280px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 34%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Tablet Vertical 4*/
@media only screen and (min-width: 800px)  and (max-width: 840px) and (min-height: 1173px) and (max-height: 1334px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 33%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Tablet Vertical 5*/
@media only screen and (min-width: 601px)  and (max-width: 632px) and (min-height: 886px) and (max-height: 1007px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 33%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Tablet Vertical 6*/
@media only screen and (min-width: 810px)  and (max-width: 851px) and (min-height: 950px) and (max-height: 1080px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 41%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}

/*Desktop Vertical 1*/
@media only screen and (min-width: 1267px)  and (max-width: 1280px) and (min-height: 870px) and (max-height: 1024px) {
    .avaregeIncome{
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 56%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 291.1%;
        background-size:contain;
        z-index: 0;
    }
}