@font-face {
    font-family: "Fira Sans";
    src: url('../../fonts/sinkinSans/SinkinSans-800Black.otf') format('otf');
    src: url('../../fonts/sinkinSans/SinkinSans-500Medium.otf') format('otf');
    src: url('../../fonts/sinkinSans/SinkinSans-400Regular.otf') format('otf');
}

.invoice {
    background-color: #00594f;
    width: 93%;
    max-width: 95%;
    position: absolute;
    height: 120%;
    background-size:contain;
    top: 0;
    left: 0;
    margin-left: 0%;
    margin-top: 230%;
    border-bottom-right-radius: 10%;
    z-index: 0;
}

.rectangle10 {
    width: 35%;
    max-width: 100%;
    height:auto;        
    left: 0;
    margin-left: 72.5%;
    position: absolute;
    top: 0;
    margin-top: -10%;
    z-index: 1;
}

.rectangle11 {
    width: 2%;
    max-width: 100%;
    height:auto;
    left: 0;
    margin-left: 68%;
    position: absolute;
    top: 0;
    margin-top: 3%;
    z-index: 1;
}

.rectangle12 {
    width: 55%;
    max-width: 100%;
    height:auto;
    left: 0;
    margin-left: 19.8%;
    position: absolute;
    top: 0;
    margin-top: 9%;
    z-index: 1;
}

.totalInvoice {
    font-family: "Sinkin Sans-800Black", Helvetica;
    font-size: 4vw;
    font-weight: 800;
    line-height: 100%;
    color: #ffffff;
    position: absolute;
    margin-left: 23%;
    margin-top: 6%;
    text-align: center;
    z-index: 1;
}

.invoiceText {
    font-family: "Sinkin Sans-500Medium", Helvetica;
    font-size: 1.5vw;
    font-weight: 500;
    line-height: 100%;
    color: #ffffff;
    position: absolute;
    letter-spacing: 0.3vw;
    margin-left: 23%;
    margin-top: 12%;
    float: left; 
}

.invoiceText2 {
    font-family: "Sinkin Sans-400Regular", Helvetica;
    font-size: 1.2vw;
    font-weight: 400;
    line-height: 100%;
    color: #ffffff;
    position: absolute;
    margin-left: 23%;
    margin-top: 19%;
    float: left; 
    z-index: 1;
}

.mapBox3 {
    background-color: #00594f;
    width: 55%;
    max-width: 80%;
    position: absolute;
    top: 0; 
    left: 0;
    padding-left: 24%;
    margin-top: 23%;
    z-index: 0;
}

.textMap3 {
    background-color: #00594f;
    color: #ffffff;
    position: absolute;
    font-family: "Sinkin Sans-500Medium", Helvetica;
    font-size: 0.8vw;
    font-weight: 500;
    top: 0;
    margin-top: 25%;
    left: 0;
    margin-left: 50%;
    z-index: 1;
}

.invoiceState {
    color: #ffffff;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 2.5vw;
    font-weight: 700;
    left: 0;
    margin-left: 65%;
    letter-spacing: 0.2vw;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: 35%;
    z-index: 1;
}

.invoiceText3 {
    font-family: "Sinkin Sans-400Regular", Helvetica;
    font-size: 1.2vw;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.13vw;
    color: #ffffff;
    position: absolute;
    margin-left: 65%;
    margin-top: 40%;
    float: left;
    z-index: 1;
}

.rectangle12Smaller {
    width: 31.5%;
    max-width: 100%;
    height:auto;
    left: 0;
    margin-left: 63.7%;
    position: absolute;
    top: 0;
    margin-top: 38%;
    z-index: 1;
}

.cardsInvoice {
    margin-left: 90px;
}

.billingCard {
    background: #49988A;
    margin: 0;
    box-shadow: 26px 17px rgba(103, 198, 155, 0.2);
    border: none;
}

.cardTitle {
    font-size: 30px;
    text-align: center;
}

.quantityCardInvoice {
    font-size: 26px;
    text-align: center
}

.cardInvoice2 {
    float: right;
}

.mapInvoice {
    color: white !important;
}


/*Mobile Vertical 1*/
@media only screen and (min-width: 412px)  and (max-width: 433px) and (min-height: 805px) and (max-height: 915px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 32%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Horizontal 1*/
@media only screen and (min-width: 869px)  and (max-width: 925px) and (min-height: 313px) and (max-height: 412px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 171%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Vertical 2*/
@media only screen and (min-width: 414px)  and (max-width: 435px) and (min-height: 788px) and (max-height: 896px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 33%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Horizontal 2*/
@media only screen and (min-width: 850px)  and (max-width: 905px) and (min-height: 314px) and (max-height: 414px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 167%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Vertical 3*/
@media only screen and (min-width: 393px)  and (max-width: 413px) and (min-height: 768px) and (max-height: 873px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 32%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Horizontal 3*/
@media only screen and (min-width: 829px)  and (max-width: 882px) and (min-height: 298px) and (max-height: 393px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 172%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Vertical 4*/
@media only screen and (min-width: 412px)  and (max-width: 433px) and (min-height: 784px) and (max-height: 892px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 33%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Horizontal 4*/
@media only screen and (min-width: 846px)  and (max-width: 901px) and (min-height: 313px) and (max-height: 412px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 167%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Vertical 5*/
@media only screen and (min-width: 360px)  and (max-width: 378px) and (min-height: 563px) and (max-height: 640px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 40%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Horizontal 5*/
@media only screen and (min-width: 608px)  and (max-width: 647px) and (min-height: 273px) and (max-height: 360px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 138%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Vertical 6*/
@media only screen and (min-width: 360px)  and (max-width: 378px) and (min-height: 704px) and (max-height: 800px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 32%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Horizontal 6*/
@media only screen and (min-width: 759px)  and (max-width: 808px) and (min-height: 273px) and (max-height: 360px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 172%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Vertical 7*/
@media only screen and (min-width: 385px)  and (max-width: 405px) and (min-height: 751px) and (max-height: 854px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 32%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Horizontal 7*/
@media only screen and (min-width: 811px)  and (max-width: 863px) and (min-height: 291px) and (max-height: 385px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 172%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Vertical 8*/
@media only screen and (min-width: 393px)  and (max-width: 413px) and (min-height: 748px) and (max-height: 851px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 33%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Horizontal 8*/
@media only screen and (min-width: 808px)  and (max-width: 860px) and (min-height: 298px) and (max-height: 393px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 167%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Vertical 9*/
@media only screen and (min-width: 412px)  and (max-width: 433px) and (min-height: 764px) and (max-height: 869px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 34%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Horizontal 9*/
@media only screen and (min-width: 825px)  and (max-width: 878px) and (min-height: 313px) and (max-height: 412px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 163%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Vertical 10*/
@media only screen and (min-width: 390px)  and (max-width: 410px) and (min-height: 742px) and (max-height: 844px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 33%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Horizontal 10*/
@media only screen and (min-width: 801px)  and (max-width: 853px) and (min-height: 296px) and (max-height: 390px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 167%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Vertical 11*/
@media only screen and (min-width: 360px)  and (max-width: 378px) and (min-height: 668px) and (max-height: 760px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 34%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Horizontal 11*/
@media only screen and (min-width: 714px)  and (max-width: 768px) and (min-height: 273px) and (max-height: 360px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 164%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Vertical 12*/
@media only screen and (min-width: 375px)  and (max-width: 394px) and (min-height: 586px) and (max-height: 667px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 40%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Horizontal 12*/
@media only screen and (min-width: 633px)  and (max-width: 674px) and (min-height: 285px) and (max-height: 375px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 137%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Vertical 13*/
@media only screen and (min-width: 384px)  and (max-width: 404px) and (min-height: 751px) and (max-height: 854px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 32%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Mobile Horizontal 13*/
@media only screen and (min-width: 811px)  and (max-width: 863px) and (min-height: 291px) and (max-height: 384px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 172%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Tablet Vertical 1*/
@media only screen and (min-width: 768px)  and (max-width: 807px) and (min-height: 901px) and (max-height: 1024px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 52%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Tablet Horizontal 1*/
@media only screen and (min-width: 972px)  and (max-width: 1035px) and (min-height: 583px) and (max-height: 768px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 103%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Tablet Vertical 2*/
@media only screen and (min-width: 601px)  and (max-width: 632px) and (min-height: 846px) and (max-height: 962px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 44%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Tablet Horizontal 2*/
@media only screen and (min-width: 913px)  and (max-width: 972px) and (min-height: 456px) and (max-height: 601px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 124%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Tablet Vertical 3*/
@media only screen and (min-width: 800px)  and (max-width: 840px) and (min-height: 1126px) and (max-height: 1280px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 44%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Tablet Horizontal 3*/
@media only screen and (min-width: 1215px)  and (max-width: 1293px) and (min-height: 608px) and (max-height: 800px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 123%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Tablet Vertical 4*/
@media only screen and (min-width: 800px)  and (max-width: 840px) and (min-height: 1173px) and (max-height: 1334px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 42%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Tablet Horizontal 4*/
@media only screen and (min-width: 1267px)  and (max-width: 1348px) and (min-height: 608px) and (max-height: 800px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 129%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Tablet Vertical 5*/
@media only screen and (min-width: 601px)  and (max-width: 632px) and (min-height: 886px) and (max-height: 1007px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 42%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Tablet Horizontal 5*/
@media only screen and (min-width: 956px)  and (max-width: 1018px) and (min-height: 456px) and (max-height: 601px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 129%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Tablet Vertical 6*/
@media only screen and (min-width: 810px)  and (max-width: 851px) and (min-height: 950px) and (max-height: 1080px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 52%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Tablet Horizontal 6*/
@media only screen and (min-width: 1025px)  and (max-width: 1091px) and (min-height: 615px) and (max-height: 810px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 103%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Desktop Vertical 1*/
@media only screen and (min-width: 1352px)  and (max-width: 1366px) and (min-height: 652px) and (max-height: 768px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 121%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Desktop Vertical 2*/
@media only screen and (min-width: 1900px)  and (max-width: 1920px) and (min-height: 918px) and (max-height: 1080px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 121%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Desktop Vertical 3*/
@media only screen and (min-width: 1520px)  and (max-width: 1536px) and (min-height: 734px) and (max-height: 864px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 121%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Desktop Vertical 4*/
@media only screen and (min-width: 1425px)  and (max-width: 1440px) and (min-height: 765px) and (max-height: 900px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 109%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Desktop Vertical 5*/
@media only screen and (min-width: 1584px)  and (max-width: 1600px) and (min-height: 765px) and (max-height: 900px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 121%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Desktop Vertical 6*/
@media only screen and (min-width: 1346px)  and (max-width: 1366px) and (min-height: 652px) and (max-height: 768px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 120%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}

/*Desktop Vertical 7*/
@media only screen and (min-width: 1267px)  and (max-width: 1280px) and (min-height: 870px) and (max-height: 1024px) {
    .invoice {
        background-color: #00594f;
        width: 93%;
        max-width: 95%;
        position: absolute;
        height: 85%;
        background-size:contain;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 230%;
        border-bottom-right-radius: 10%;
        z-index: 0;
    }
}