@font-face {
    font-family: "Fira Sans";
    src: url('../../fonts/sinkinSans/SinkinSans-500Medium.otf') format('otf');
    src: url('../../fonts/sinkinSans/SinkinSans-700Bold.otf') format('otf');
}

.divisor {
    background-color: #dddddd;
    width: 100%;
    max-width: 100%;
    position: absolute;
    height: 98%;
    top: 0;
    left: 0;
    margin-left: 0%;
    margin-top: 50.9%;
    background-size:cover;
    z-index: 0;
}

.divisor2 {
    background-color: #dddddd;
    width: 55%;
    max-width: 55%;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 45%;
    margin-top: -2%;
    z-index: 0;
}

.textMap {
    background-color: #dddddd;
    color: #00594f;
    position: absolute;
    font-family: "Sinkin Sans-500Medium", Helvetica;
    font-size: 0.8vw;
    font-weight: 500;
    top: 0;
    margin-top: 0%;
    left: 0;
    margin-left: 71%;
    z-index: 1;
}

.rectangle4 {
    width: 2%;
    max-width: 80%;
    height:auto;
    left: 0;
    margin-left: 20%;
    position: absolute;
    top: 0;
    margin-top: -10%;
    z-index: 1;
}

.totalcooperativa {
    text-align: center;
    width: 100%;
    top: 0;
    margin-top: -105%;
    z-index: 1;
}

.organization {
    color: #00594f;
    width: 100%;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-weight: 700;
    top: 0;
    margin-top: 99%;
    text-align: left;
    font-size: 2vw;
    left: 0;
    padding-left: 25%;
    z-index: 1;
    pointer-events:none;
}

.p{
    color: #00594f;
    font-family: "Sinkin Sans-500Medium", Helvetica;
    font-size: 1.5vw;
    font-weight: 500;
    line-height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 19%;
    margin-top: 7%;
    z-index: 1;
    pointer-events:none;
}

.p2{
    color: #00594f;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 4vw;
    font-weight: 700;
    line-height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 23%;
    margin-top: 17%;
    z-index: 1;
    pointer-events:none;
}

.p3{
    color: #00594f;
    font-family: "Sinkin Sans-500Medium", Helvetica;
    font-size: 1.5vw;
    font-weight: 500;
    line-height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 19%;
    margin-top: 13%;
    z-index: 1;
    pointer-events:none;
}

.p4{
    color: #00594f;
    font-family: "Sinkin Sans-500Medium", Helvetica;
    font-size: 1.5vw;
    font-weight: 500;
    line-height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 19%;
    margin-top: 19%;
    z-index: 1;
    pointer-events:none;
}

.p6{
    color: #00594f;
    font-family: "Sinkin Sans-500Medium", Helvetica;
    font-size: 1.5vw;
    font-weight: 500;
    line-height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 19%;
    margin-top: 26%;
    z-index: 1;
    pointer-events:none;
}

.p7{
    color: #00594f;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 3vw;
    font-weight: 700;
    line-height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 34%;
    margin-top: 31%;
    z-index: 1;
    pointer-events:none;
}

.p8{
    color: #00594f;
    font-family: "Sinkin Sans-500Medium", Helvetica;
    font-size: 1vw;
    font-weight: 500;
    line-height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 34%;
    margin-top: 34%;
    z-index: 1;
    pointer-events:none;
}

.p10{
    color: #00594f;
    font-family: "Sinkin Sans-500Medium", Helvetica;
    font-size: 1vw;
    font-weight: 500;
    line-height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 34%;
    margin-top: 40%;
    z-index: 1;
    pointer-events:none;
}

.rectangle5 {
    width: 49%;
    max-width: 84%;
    height:auto;
    left: 0;
    margin-left: 16%;
    position: absolute;
    top: 0;
    margin-top: 2%;
    z-index: 1;
    pointer-events:none;
}

/*Mobile Horizontal 1*/
@media only screen and (min-width: 869px)  and (max-width: 925px) and (min-height: 313px) and (max-height: 412px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 143%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 2*/
@media only screen and (min-width: 850px)  and (max-width: 905px) and (min-height: 314px) and (max-height: 414px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 140%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 3*/
@media only screen and (min-width: 829px)  and (max-width: 882px) and (min-height: 298px) and (max-height: 393px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 145%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 4*/
@media only screen and (min-width: 846px)  and (max-width: 901px) and (min-height: 313px) and (max-height: 412px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 141%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 5*/
@media only screen and (min-width: 608px)  and (max-width: 647px) and (min-height: 273px) and (max-height: 360px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 141%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 6*/
@media only screen and (min-width: 759px)  and (max-width: 808px) and (min-height: 273px) and (max-height: 360px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 145%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 7*/
@media only screen and (min-width: 811px)  and (max-width: 863px) and (min-height: 292px) and (max-height: 385px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 145%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 8*/
@media only screen and (min-width: 808px)  and (max-width: 860px) and (min-height: 298px) and (max-height: 393px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 141%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 9*/
@media only screen and (min-width: 825px)  and (max-width: 878px) and (min-height: 313px) and (max-height: 412px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 141%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 10*/
@media only screen and (min-width: 801px)  and (max-width: 853px) and (min-height: 296px) and (max-height: 390px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 141%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 11*/
@media only screen and (min-width: 714px)  and (max-width: 768px) and (min-height: 273px) and (max-height: 360px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 141%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 12*/
@media only screen and (min-width: 633px)  and (max-width: 674px) and (min-height: 285px) and (max-height: 375px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 141%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 13*/
@media only screen and (min-width: 811px)  and (max-width: 863px) and (min-height: 291px) and (max-height: 384px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 145%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Tablet Horizontal 1*/
@media only screen and (min-width: 972px)  and (max-width: 1035px) and (min-height: 583px) and (max-height: 768px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 105%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Tablet Horizontal 2*/
@media only screen and (min-width: 913px)  and (max-width: 972px) and (min-height: 456px) and (max-height: 601px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 105%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Tablet Horizontal 3*/
@media only screen and (min-width: 1280px)  and (max-width: 1293px) and (min-height: 608px) and (max-height: 800px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 105%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Tablet Horizontal 4*/
@media only screen and (min-width: 1334px)  and (max-width: 1348px) and (min-height: 608px) and (max-height: 800px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 109%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Tablet Horizontal 5*/
@media only screen and (min-width: 956px)  and (max-width: 1018px) and (min-height: 456px) and (max-height: 601px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 110%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Tablet Horizontal 6*/
@media only screen and (min-width: 1025px)  and (max-width: 1091px) and (min-height: 615px) and (max-height: 810px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 110%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}


/*Desktop Vertical 1*/
@media only screen and (min-width: 1352px)  and (max-width: 1366px) and (min-height: 652px) and (max-height: 768px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 103%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Desktop Vertical 2*/
@media only screen and (min-width: 1900px)  and (max-width: 1920px) and (min-height: 918px) and (max-height: 1080px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 103%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Desktop Vertical 3*/
@media only screen and (min-width: 1520px)  and (max-width: 1536px) and (min-height: 734px) and (max-height: 864px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 103%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Desktop Vertical 4*/
@media only screen and (min-width: 1267px)  and (max-width: 1280px) and (min-height: 612px) and (max-height: 720px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 103%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Desktop Vertical 5*/
@media only screen and (min-width: 1584px)  and (max-width: 1600px) and (min-height: 765px) and (max-height: 900px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 103%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}

/*Desktop Vertical 6*/
@media only screen and (min-width: 1346px)  and (max-width: 1366px) and (min-height: 652px) and (max-height: 768px) {
    .divisor {
        background-color: #dddddd;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 102%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 50.9%;
        background-size:cover;
        z-index: 0;
    }
}