.bodyCardCollectors{
    text-align: left;
}

.cardTextCollectors{
    color: hsl(207, 15%, 14%);
    font-weight: 600;
}

.cardTextCollectors p {
    float: left !important;
}

.table {
    max-height: 300px;
}

.modal-body{
    text-align: left;
    align-self: center;
    height: 300px;
    overflow-y:auto;
}

.modal-body th{
    width: 170px;
}

.buttonCard{
    background: #459788;
    border: none;
}

