@font-face {
    font-family: "Fira Sans";
    src: url('../../fonts/sinkinSans/SinkinSans-400Regular.otf') format('otf');
}

.login {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
    background: #49988A;
    font-family: "Sinkin Sans-400Regular", Helvetica;
    font-size: 1.4vw;
    z-index: 1;
}

p{
    color: #49988A;
    margin: 0;
    padding: 0;
    float: right;
    padding-right: 5%;
    font-weight: 400;
}

a{
    text-decoration: underline;
}

.enterButton{
    background: #34685F;
    border: none;
}

.enterButton:hover{
    background: #49988A !important;
}

.forgotPassword{
    font-size: 12px;
}

#formBasicPassword{
    margin-bottom: 0 !important;
}

.register{
    text-decoration: none;
    font-weight: 500;
}

.bodyModal{
    
}

.loginModal{
    
}