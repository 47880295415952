@font-face {
    font-family: "Fira Sans";
    src: url('../../fonts/sinkinSans/SinkinSans-800Black.otf') format('otf');
    src: url('../../fonts/sinkinSans/SinkinSans-400Regular.otf') format('otf');
}

.formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 103.1%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
}

.rectangle13 {
    width: 40%;
    max-width: 100%;
    height:100%;
    left: 0;
    margin-left: 60%;
    position: absolute;
    top: 0;
    margin-top: 0%;
    z-index: 1;
}

.textForm {
    font-family: "Sinkin Sans-800Black", Helvetica;
    font-size: 2.5vw;
    font-weight: 800;
    line-height: 100%;
    letter-spacing: 0.1vw;
    color: #00594f;
    position: absolute;
    margin-left: 10%;
    margin-top: 5%;
    float: left;
    z-index: 1;
}

.formAnuario1 {
    -ms-box-sizing:content-box;
    -moz-box-sizing:content-box;
    -webkit-box-sizing:content-box; 
    box-sizing:content-box;
    position: absolute;
    width: 37%;
    border-radius: 8px;
    border: white solid 1px;
    font-family: "Sinkin Sans-400Regular", Helvetica;
    font-size: 1.5vw;
    font-weight: 400;
    margin-left: 10%;
    margin-top: 18%;
    z-index: 1;
}

.formAnuario2 {
    -ms-box-sizing:content-box;
    -moz-box-sizing:content-box;
    -webkit-box-sizing:content-box; 
    box-sizing:content-box;
    position: absolute;
    width: 37%;
    border-radius: 8px;
    border: white solid 1px;
    font-family: "Sinkin Sans-400Regular", Helvetica;
    font-size: 1.5vw;
    font-weight: 400;
    margin-left: 10%;
    margin-top: 21%;
    z-index: 1;
}

.formAnuario3 {
    -ms-box-sizing:content-box;
    -moz-box-sizing:content-box;
    -webkit-box-sizing:content-box; 
    box-sizing:content-box;
    position: absolute;
    width: 37%;
    border-radius: 8px;
    border: white solid 1px;
    font-family: "Sinkin Sans-400Regular", Helvetica;
    font-size: 1.5vw;
    font-weight: 400;
    margin-left: 10%;
    margin-top: 24%;
    z-index: 1;
}

.formAnuario4 {
    -ms-box-sizing:content-box;
    -moz-box-sizing:content-box;
    -webkit-box-sizing:content-box; 
    box-sizing:content-box;
    position: absolute;
    width: 37.4%;
    border-radius: 8px;
    border: white solid 1px;
    font-family: "Sinkin Sans-400Regular", Helvetica;
    font-size: 1.5vw;
    font-weight: 400;
    margin-left: 10%;
    margin-top: 27%;
    z-index: 1;
}

.formAnuario5 {
    -ms-box-sizing:content-box;
    -moz-box-sizing:content-box;
    -webkit-box-sizing:content-box; 
    box-sizing:content-box;
    position: absolute;
    width: 37.4%;
    border-radius: 8px;
    border: white solid 1px;
    font-family: "Sinkin Sans-400Regular", Helvetica;
    font-size: 1.5vw;
    font-weight: 400;
    margin-left: 10%;
    margin-top: 30%;
    z-index: 1;
}

.formAnuario6 {
    -ms-box-sizing:content-box;
    -moz-box-sizing:content-box;
    -webkit-box-sizing:content-box; 
    box-sizing:content-box;
    position: absolute;
    width: 37%;
    border-radius: 8px;
    border: white solid 1px;
    font-family: "Sinkin Sans-400Regular", Helvetica;
    font-size: 1.5vw;
    font-weight: 400;
    margin-left: 10%;
    margin-top: 33%;
    z-index: 1;
}

.formAnuario7 {
    -ms-box-sizing:content-box;
    -moz-box-sizing:content-box;
    -webkit-box-sizing:content-box; 
    box-sizing:content-box;
    position: absolute;
    width: 37%;
    border-radius: 8px;
    border: white solid 1px;
    font-family: "Sinkin Sans-400Regular", Helvetica;
    font-size: 1.5vw;
    font-weight: 400;
    margin-left: 10%;
    margin-top: 36%;
    z-index: 1;
}

.formAnuario8 {
    -ms-box-sizing:content-box;
    -moz-box-sizing:content-box;
    -webkit-box-sizing:content-box; 
    box-sizing:content-box;
    position: absolute;
    width: 5%;
    border-radius: 8px;
    border: #56c2b6 solid 1px;
    font-family: "Sinkin Sans-400Regular", Helvetica;
    font-size: 1.5vw;
    background-color: #56c2b6;
    color: #ffffff;
    font-weight: 400;
    margin-left: 26%;
    margin-top: 40.5%;
    cursor:pointer;
    z-index: 1;
}

.formAnuario8:active,
.formAnuario8.open,
.formAnuario8:focus {
    /* border-color: rgb(73, 152, 138, 1 )!important; */
    box-shadow: 0 0 0 1px rgb(0 89 79 / 50%) !important;
    background-color: #00594f!important;
    text-align: center;
    border-radius: 12px;
    /* cor da borda quando o select estiver aberto */
}

.download {
    background: #34685F;
    padding: 20px 20px 20px 20px;
}

.downloadLink {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}

.app {
    font-size: 30px;
    font-weight: 400;
    text-align:center;
}

.linkDownload {
    font-size: 25px;
    font-weight: 300;
    text-align:center;
    float: none;
}

.imgSection3 {
    padding-left: 160px;
}

.link {
    color: white;
    text-align:center;
    text-decoration: none;
}

.link:hover {
    color: #00594f;
    text-align:center;
}

/*Mobile Vertical 1*/
@media only screen and (min-width: 412px)  and (max-width: 433px) and (min-height: 805px) and (max-height: 915px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 24%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 1*/
@media only screen and (min-width: 869px)  and (max-width: 925px) and (min-height: 313px) and (max-height: 412px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 127%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 2*/
@media only screen and (min-width: 414px)  and (max-width: 435px) and (min-height: 788px) and (max-height: 896px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 24%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 2*/
@media only screen and (min-width: 850px)  and (max-width: 905px) and (min-height: 314px) and (max-height: 414px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 124%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 3*/
@media only screen and (min-width: 393px)  and (max-width: 413px) and (min-height: 768px) and (max-height: 873px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 24%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 3*/
@media only screen and (min-width: 829px)  and (max-width: 882px) and (min-height: 298px) and (max-height: 393px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 127%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 4*/
@media only screen and (min-width: 412px)  and (max-width: 433px) and (min-height: 784px) and (max-height: 892px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 25%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 4*/
@media only screen and (min-width: 846px)  and (max-width: 901px) and (min-height: 313px) and (max-height: 412px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 124%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 5*/
@media only screen and (min-width: 360px)  and (max-width: 378px) and (min-height: 563px) and (max-height: 640px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 30%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 5*/
@media only screen and (min-width: 608px)  and (max-width: 647px) and (min-height: 273px) and (max-height: 360px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 103%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 6*/
@media only screen and (min-width: 360px)  and (max-width: 378px) and (min-height: 704px) and (max-height: 800px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 24%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 6*/
@media only screen and (min-width: 759px)  and (max-width: 808px) and (min-height: 273px) and (max-height: 360px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 127%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 7*/
@media only screen and (min-width: 385px)  and (max-width: 405px) and (min-height: 751px) and (max-height: 854px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 24%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 7*/
@media only screen and (min-width: 811px)  and (max-width: 863px) and (min-height: 291px) and (max-height: 385px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 128%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 8*/
@media only screen and (min-width: 393px)  and (max-width: 413px) and (min-height: 748px) and (max-height: 851px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 24%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 8*/
@media only screen and (min-width: 808px)  and (max-width: 860px) and (min-height: 298px) and (max-height: 393px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 124%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 9*/
@media only screen and (min-width: 412px)  and (max-width: 433px) and (min-height: 764px) and (max-height: 869px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 25%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 9*/
@media only screen and (min-width: 825px)  and (max-width: 878px) and (min-height: 313px) and (max-height: 412px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 121%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 10*/
@media only screen and (min-width: 390px)  and (max-width: 410px) and (min-height: 742px) and (max-height: 844px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 25%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 10*/
@media only screen and (min-width: 801px)  and (max-width: 853px) and (min-height: 296px) and (max-height: 390px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 124%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 11*/
@media only screen and (min-width: 360px)  and (max-width: 378px) and (min-height: 668px) and (max-height: 760px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 25%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 11*/
@media only screen and (min-width: 714px)  and (max-width: 768px) and (min-height: 273px) and (max-height: 360px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 121%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 12*/
@media only screen and (min-width: 375px)  and (max-width: 394px) and (min-height: 586px) and (max-height: 667px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 30%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 12*/
@media only screen and (min-width: 633px)  and (max-width: 674px) and (min-height: 285px) and (max-height: 375px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 102%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 13*/
@media only screen and (min-width: 384px)  and (max-width: 404px) and (min-height: 751px) and (max-height: 854px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 24%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Horizontal 13*/
@media only screen and (min-width: 811px)  and (max-width: 863px) and (min-height: 291px) and (max-height: 384px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 128%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Tablet Vertical 1*/
@media only screen and (min-width: 768px)  and (max-width: 807px) and (min-height: 901px) and (max-height: 1024px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 39%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Tablet Horizontal 1*/
@media only screen and (min-width: 972px)  and (max-width: 1035px) and (min-height: 583px) and (max-height: 768px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 76%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Tablet Vertical 2*/
@media only screen and (min-width: 601px)  and (max-width: 632px) and (min-height: 846px) and (max-height: 962px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 33%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Tablet Horizontal 2*/
@media only screen and (min-width: 913px)  and (max-width: 972px) and (min-height: 456px) and (max-height: 601px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 91%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Tablet Vertical 3*/
@media only screen and (min-width: 800px)  and (max-width: 840px) and (min-height: 1126px) and (max-height: 1280px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 33%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Tablet Horizontal 3*/
@media only screen and (min-width: 1215px)  and (max-width: 1293px) and (min-height: 608px) and (max-height: 800px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 91%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Tablet Vertical 4*/
@media only screen and (min-width: 800px)  and (max-width: 840px) and (min-height: 1173px) and (max-height: 1334px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 31%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Tablet Horizontal 4*/
@media only screen and (min-width: 1267px)  and (max-width: 1348px) and (min-height: 608px) and (max-height: 800px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 95%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Tablet Vertical 5*/
@media only screen and (min-width: 601px)  and (max-width: 632px) and (min-height: 886px) and (max-height: 1007px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 31%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Tablet Horizontal 5*/
@media only screen and (min-width: 956px)  and (max-width: 1018px) and (min-height: 456px) and (max-height: 601px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 96%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Tablet Vertical 6*/
@media only screen and (min-width: 810px)  and (max-width: 851px) and (min-height: 950px) and (max-height: 1080px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 39%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Tablet Horizontal 6*/
@media only screen and (min-width: 1025px)  and (max-width: 1091px) and (min-height: 615px) and (max-height: 810px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 76%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Desktop Vertical 1*/
@media only screen and (min-width: 1352px)  and (max-width: 1366px) and (min-height: 652px) and (max-height: 768px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 90%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Desktop Vertical 2*/
@media only screen and (min-width: 1900px)  and (max-width: 1920px) and (min-height: 918px) and (max-height: 1080px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 90%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Desktop Vertical 3*/
@media only screen and (min-width: 1520px)  and (max-width: 1536px) and (min-height: 734px) and (max-height: 864px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 90%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Desktop Vertical 4*/
@media only screen and (min-width: 1425px)  and (max-width: 1440px) and (min-height: 765px) and (max-height: 900px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 81%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Desktop Vertical 5*/
@media only screen and (min-width: 1584px)  and (max-width: 1600px) and (min-height: 765px) and (max-height: 900px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 90%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Desktop Vertical 6*/
@media only screen and (min-width: 1346px)  and (max-width: 1366px) and (min-height: 652px) and (max-height: 768px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 90%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}

/*Desktop Vertical 7*/
@media only screen and (min-width: 1267px)  and (max-width: 1280px) and (min-height: 870px) and (max-height: 1024px) {
    .formDownload {
        background-image: linear-gradient(#ffffff, #00594f);
        width: 100%;
        max-width: 100%;
        height: 63%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 340%;
        background-size:cover;
        z-index: 0;
    }
}