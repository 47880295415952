@font-face {
    font-family: "Fira Sans";
    src: url('../../fonts/sinkinSans/SinkinSans-700Bold.otf') format('otf');
}

.p5{
    color: #00594f;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 4vw;
    font-weight: 700;
    line-height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 19%;
    margin-top: 121%;
    z-index: 1;
    pointer-events:none;
}

.p9{
    color: #00594f;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 3vw;
    font-weight: 700;
    line-height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 34%;
    margin-top: 137%;
    text-align: left;
    z-index: 1;
    pointer-events:none;
}