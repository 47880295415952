.input-group {
    width: 60%;
    margin: 0 auto;
    padding-top: 30px;
}

.search h4{
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    font-weight: 400;
}

.form-control:focus{
    box-shadow: none;
}