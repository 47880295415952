@font-face {
    font-family: "Fira Sans";
    src: url('../../../fonts/sinkinSans/SinkinSans-700Bold.otf') format('otf');
}

.buttonGroup{
    text-align: center;
    padding-bottom: 30px;
}

.button{
    background: #459788;
    border: none;
}

.button:active{
    background: #1C2226 ; 
}

.button:visited{
    background: #1C2226 ; 
}

.button:focus{
    background: #1C2226 ; 
}

.button:hover{
    background: #1F252A;}