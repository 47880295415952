.formToFill{
    background: #49988A;
    text-align: center;
    padding: 40px 40px 40px 40px;
}

.formToFillText{
    font-size: 30px;
    font-weight: 400;
    padding-bottom: 30px;
    text-align: left;
    color: white;
}

.formButton{
    background: #34685F;
    border: none; 
    margin-left: 10px;
}



/*@media screen and (max-width: 600px) {
    .formToFillText{
        text-align: center;
        margin: 0;
        padding: 0;
        font-size: 25px;
        padding-bottom: 10px;
    }
    .formToFill{
        padding: 20px;
    }
}*/