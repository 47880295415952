@font-face {
    font-family: "Fira Sans";
    src: url('../../fonts/sinkinSans/SinkinSans-700Bold.otf') format('otf');
    src: url('../../fonts/sinkinSans/SinkinSans-600SemiBold.otf') format('otf');
    src: url('../../fonts/sinkinSans/SinkinSans-500Medium.otf') format('otf');
}

.comercializacao {
    background-color: #ffffff;
    width: 100%;
    max-width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    margin-left: 0%;
    margin-top: 150%;
    background-size:cover;
    z-index: 0;
}

.rectangle6 {
    width: 50%;
    max-width: 100%;
    height:auto;
    left: 0;
    margin-left: 0%;
    position: absolute;
    top: 0;
    margin-top: 0%;
    z-index: 1;
}

.rectangle7 {
    width: 40%;
    max-width: 100%;
    height:auto;
    left: 0;
    margin-left: 47.1%;
    position: absolute;
    top: 0;
    margin-top: 12.5%;
    z-index: 0;
}

.rectangle8 {
    width: 2%;
    max-width: 100%;
    height:auto;
    left: 0;
    margin-left: 52%;
    position: absolute;
    top: 0;
    margin-top: 7%;
    z-index: 1;
}

.rectangle9 {
    width: 2%;
    max-width: 100%;
    height:auto;
    left: 0;
    margin-left: 52.5%;
    position: absolute;
    top: 0;
    margin-top: 7%;
    z-index: 1;
}

.totalComercializado {
    text-align: center;
    padding: 20px 20px 20px 20px;
}

.comercializado{
    font-family: "Sinkin Sans-500Medium", Helvetica;
    font-size: 1.5vw;
    font-weight: 500;
    line-height: 100%;
    color: #1A9587;
    position: absolute;
    margin-left: 53%;
    margin-top: 16%;
    float: left; 
}

.totalMaterial{
    text-align: right;
    color: #1A9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 4vw;
    font-weight: 700;
    line-height: 100%;
    position: absolute;
    margin-left: 52%;
    margin-top: 10%;
    float: left;
    padding-right: 0%;
}

.ton{
    font-family: "Sinkin Sans-600SemiBold", Helvetica;
    font-size: 1.5vw;
    font-weight: 600;
    line-height: 100%;
    color: #1A9587;
    position: absolute;
    margin-left: 103%;
    margin-top: -15%;
    float: left; 
}

.detalhesTexto{
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 1.4vw;
    font-weight: 700;
    line-height: 100%;
    color: #1A9587;
    position: absolute;
    margin-left: 29%;
    margin-top: 30%;
    text-align: center; 
}

.mapBox2{
    background-color: #ffffff;
    width: 55%;
    max-width: 80%;
    position: absolute;
    top: 0; 
    left: 0;
    padding-left: 21%;
    margin-top: 35%;
    z-index: 0;
}

.textMap2 {
    background-color: #ffffff;
    color: #00594f;
    position: absolute;
    font-family: "Sinkin Sans-500Medium", Helvetica;
    font-size: 0.8vw;
    font-weight: 500;
    top: 0;
    margin-top: 37%;
    left: 0;
    margin-left: 47%;
    z-index: 1;
}

.text1 {
    color: #1a9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 1.5vw;
    font-weight: 700;
    left: 0;
    margin-left: 65%;
    letter-spacing: 0.2vw;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: 40%;
    z-index: 1;
}

.text2 {
    color: #1a9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 1.5vw;
    font-weight: 700;
    left: 0;
    margin-left: 65%;
    letter-spacing: 0.2vw;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: 45%;
    z-index: 1;
}

.text3 {
    color: #1a9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 1.5vw;
    font-weight: 700;
    left: 0;
    margin-left: 65%;
    letter-spacing: 0.2vw;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: 50%;
    z-index: 1;
}

.text4 {
    color: #1a9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 1.5vw;
    font-weight: 700;
    left: 0;
    margin-left: 65%;
    letter-spacing: 0.2vw;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: 55%;
    z-index: 1;
}

.text5 {
    color: #1a9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 1.5vw;
    font-weight: 700;
    left: 0;
    margin-left: 65%;
    letter-spacing: 0.2vw;
    line-height: 130%;
    position: absolute;
    top: 0; 
    margin-top: 60%;
    z-index: 1;
}

.text6 {
    color: #1a9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 1.5vw;
    font-weight: 700;
    left: 0;
    margin-left: 65%;
    letter-spacing: 0.2vw;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: 65%;
    z-index: 1;
}

.text7 {
    color: #1a9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 2.2vw;
    font-weight: 700;
    left: 0;
    margin-left: 60%;
    letter-spacing: 0.2vw;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: -5%;
    z-index: 1;
}

.text8 {
    color: #1a9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 2.2vw;
    font-weight: 700;
    left: 0;
    margin-left: 65%;
    letter-spacing: 0.2vw;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: -4%;
    z-index: 1;
}

.text9 {
    color: #1a9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 2.2vw;
    font-weight: 700;
    left: 0;
    margin-left: 60%;
    letter-spacing: 0.2vw;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: -5%;
    z-index: 1;
}

.text10 {
    color: #1a9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 2.2vw;
    font-weight: 700;
    left: 0;
    margin-left: 60%;
    letter-spacing: 0.2vw;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: -5%;
    z-index: 1;;
}

.text11 {
    color: #1a9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 2.2vw;
    font-weight: 700;
    left: 0;
    margin-left: 60%;
    letter-spacing: 0.2vw;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: -5%;
    z-index: 1;
}

.text12 {
    color: #1a9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 2.2vw;
    font-weight: 700;
    left: 0;
    margin-left: 55%;
    letter-spacing: 0.2vw;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: -6%;
    z-index: 1;
}

.text13 {
    color: #1a9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 0.8vw;
    font-weight: 700;
    left: 0;
    margin-left: 100%;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: 3%;
    z-index: 1;
}

.text14 {
    color: #1a9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 0.8vw;
    font-weight: 700;
    left: 0;
    margin-left: 100%;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: 3%;
    z-index: 1;
}

.text15 {
    color: #1a9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 0.8vw;
    font-weight: 700;
    left: 0;
    margin-left: 100%;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: 3%;
    z-index: 1;
}

.text16 {
    color: #1a9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 0.8vw;
    font-weight: 700;
    left: 0;
    margin-left: 100%;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: 3%;
    z-index: 1;
}

.text17 {
    color: #1a9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 0.8vw;
    font-weight: 700;
    left: 0;
    margin-left: 100%;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: 20%;
    z-index: 1;
}

.text18 {
    color: #1a9587;
    font-family: "Sinkin Sans-700Bold", Helvetica;
    font-size: 0.8vw;
    font-weight: 700;
    left: 0;
    margin-left: 100%;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: 3%;
    z-index: 1;
}

/*Mobile Vertical 1*/
@media only screen and (min-width: 412px)  and (max-width: 433px) and (min-height: 805px) and (max-height: 915px) {
    .comercializacao {
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 67%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 150%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 2*/
@media only screen and (min-width: 414px)  and (max-width: 435px) and (min-height: 788px) and (max-height: 896px) {
    .comercializacao {
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 70%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 150%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 3*/
@media only screen and (min-width: 393px)  and (max-width: 413px) and (min-height: 768px) and (max-height: 873px) {
    .comercializacao {
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 72%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 150%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 4*/
@media only screen and (min-width: 412px)  and (max-width: 433px) and (min-height: 784px) and (max-height: 892px) {
    .comercializacao {
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 71%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 150%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 5*/
@media only screen and (min-width: 360px)  and (max-width: 378px) and (min-height: 563px) and (max-height: 640px) {
    .comercializacao {
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 69%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 150%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 6*/
@media only screen and (min-width: 360px)  and (max-width: 378px) and (min-height: 704px) and (max-height: 800px) {
    .comercializacao {
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 72%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 150%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 7*/
@media only screen and (min-width: 385px)  and (max-width: 405px) and (min-height: 751px) and (max-height: 854px) {
    .comercializacao {
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 72%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 150%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 8*/
@media only screen and (min-width: 393px)  and (max-width: 413px) and (min-height: 748px) and (max-height: 851px) {
    .comercializacao {
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 71%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 150%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 9*/
@media only screen and (min-width: 412px)  and (max-width: 433px) and (min-height: 764px) and (max-height: 869px) {
    .comercializacao {
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 71%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 150%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 10*/
@media only screen and (min-width: 390px)  and (max-width: 410px) and (min-height: 742px) and (max-height: 844px) {
    .comercializacao {
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 71%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 150%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 11*/
@media only screen and (min-width: 360px)  and (max-width: 378px) and (min-height: 668px) and (max-height: 760px) {
    .comercializacao {
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 70%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 150%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 12*/
@media only screen and (min-width: 375px)  and (max-width: 394px) and (min-height: 586px) and (max-height: 667px) {
    .comercializacao {
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 69%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 150%;
        background-size:cover;
        z-index: 0;
    }
}

/*Mobile Vertical 13*/
@media only screen and (min-width: 384px)  and (max-width: 404px) and (min-height: 751px) and (max-height: 854px) {
    .comercializacao {
        background-color: #ffffff;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 72%;
        top: 0;
        left: 0;
        margin-left: 0%;
        margin-top: 150%;
        background-size:cover;
        z-index: 0;
    }
}