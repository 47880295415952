.buttonGroup{
    text-align: center;
}

.button:active{
    background: #1C2226;
}

.button:visited{
    background: #1C2226 ; 
}

.button:focus{
    background: #1C2226 ; 
}

.button:hover{
    background: #1F252A;
}

.Norte{
    background: #459788;
    border: none;
}

.Nordeste{
    background: #246A85;
    border: none;
}

.Sudeste{
    background: #D5824A;
    border: none;
}

.Sul{
    background: #B8645F;
    border: none;
}

.Centro-Oeste{
    background: #35468A;
    border: none;
}

.RO,.AC, .AM, .PA, .RO, .RR, .AP, .TO{
    background: #459788;
    border: none;
}

.MA, .PI, .CE, .RN, .PB, .PE, .AL, .SE, .BA{
    background: #246A85;
    border: none;
}

.DF, .GO, .MT, .MS{
    background: #35468A;
    border: none;
}

.MG, .RJ, .ES, .SP{
    background: #D5824A;
    border: none;
}

.PR, .RS, .SC{
    background: #B8645F;
    border: none;
}