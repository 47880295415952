.cardTextAvarege {
    color: hsl(207, 15%, 14%);
    text-align: left !important;

}

.bodyCardAvarege{
    overflow-y:auto;
    padding: 0;
}

.cardAvarege{
    margin-top: 10px;
}

.cardavAregeIndex{
    margin-top: 10px;
}