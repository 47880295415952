@font-face {
    font-family: "Fira Sans";
    src: url('../../fonts/sinkinSans/SinkinSans-600SemiBold.otf') format('otf');
    src: url('../../fonts/sinkinSans/SinkinSans-400Regular.otf') format('otf');
    src: url('../../fonts/sinkinSans/SinkinSans-700Bold.otf') format('otf');
}

.yearbook {
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: inline-block;
}

.rectangle {
    width: 100%;
    max-width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 1.6%;
}

.text-wrapper {
    color: #ffffff;
    font-family: "Sinkin Sans-600SemiBold", Helvetica;
    font-size: 4vw;
    font-weight: 600;
    left: 0;
    margin-left: 19%;
    letter-spacing: 0.2vw;
    line-height: 130%;
    position: absolute;
    top: 0;
    margin-top: 9%;
    z-index: 1;
}

.rectangle2 {
    width: 77%;
    max-width: 84%;
    height:auto;
    left: 0;
    margin-left: 16%;
    position: absolute;
    top: 0;
    margin-top: 14%;
    z-index: 1;
}

.box {
    background-color: #00594f;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    color: #ffffff;
    font-family: "Sinkin Sans-400Regular", Helvetica;
    font-size: 1.5vw;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 120%;  
    width: 33.9%;
    padding: 3%;
    padding-top: 6.42%;
    padding-bottom: 6.42%;
    height:auto;
    left: 0;
    margin-left: 10.5%;
    position: absolute;
    top: 0;
    margin-top: 48%;
    z-index: 1;
}

.rectangle3 {
    background-color: #ffffff;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    width: 40.2%;
    max-width: 49.6%;
    padding: 0%;
    height:auto;
    left: 0;
    margin-left: 50.4%;
    padding-bottom: 0.35%;
    position: absolute;
    top: 0;
    margin-top: 48%;
    z-index: 1;
}

.select {
    color: #00594f;
    font-size: 2.5vw;
    width: 100%;
    max-width: 100%;
    margin: auto;
    text-align: center;
    position: absolute;
    left: 0;
    margin-top: 82%;
    margin-left: 0%;
    z-index: 1;
}

.selectYear {
    margin-top: 88%;
    border-radius: 14px;
    width: 30%;
    max-width: 49.2%;
    font-size: 2.5vw;
    text-align: center;
    position: absolute;
    left: 50%;
    margin-left: 0%;
    transform: translate(-50%, -50%);
    padding: 0;
    background: #00594f;
    border: none;
    color: white;
    background-repeat: no-repeat;
    z-index: 1;
}

.selectYear:active,
.selectYear.open,
.selectYear:focus {
    /* border-color: rgb(73, 152, 138, 1 )!important; */
    box-shadow: 0 0 0 1px rgb(0 89 79 / 50%) !important;
    text-align: center;
    border-radius: 12px;
    /* cor da borda quando o select estiver aberto */
}

.selectYear, .option:hover,
.selectYear, .option.focus,
.selectYear, .option.selected.focus {
    background-color: rgb(0, 89, 79 )!important;
    /* corde fundo da option selecionada e ao passar o mouse */
    color: white !important;
    text-align: center;
    border-radius: 12px;
    /* cor da option ao passar o mouse e do item selecionado*/
}

.cooperative {
    width: 50%;
    max-width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 100%;
    z-index: 1;
}

.cooperative2 {
    width: 50%;
    max-width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 100%;
    z-index: 1;
}