.buttonGroup{
    text-align: center;
}

.button-Norte:active{
    background: #1C2226;
}

.button-Norte:visited{
    background: #1C2226 ; 
}

.button-Norte:focus{
    background: #1C2226 ; 
}

.button-Norte:hover{
    background: #1F252A;
}

.button-Nordeste:active{
    background: #1C2226;
}

.button-Nordeste:visited{
    background: #1C2226 ; 
}

.button-Nordeste:focus{
    background: #1C2226 ; 
}

.button-Nordeste:hover{
    background: #1F252A;
}

.button-Sudeste:active{
    background: #1C2226;
}

.button-Sudeste:visited{
    background: #1C2226 ; 
}

.button-Sudeste:focus{
    background: #1C2226 ; 
}

.button-Sudeste:hover{
    background: #1F252A;
}

.button-Sul:active{
    background: #1C2226;
}

.button-Sul:visited{
    background: #1C2226 ; 
}

.button-Sul:focus{
    background: #1C2226 ; 
}

.button-Sul:hover{
    background: #1F252A;
}

.button-Centro-Oeste:active{
    background: #1C2226;
}

.button-Centro-Oeste:visited{
    background: #1C2226 ; 
}

.button-Centro-Oeste:focus{
    background: #1C2226 ; 
}

.button-Centro-Oeste:hover{
    background: #1F252A;
}

.button-Norte{
    background: #459788;
    border: none;
}

.button-Nordeste{
    background: #246A85;
    border: none;
}

.button-Sudeste{
    background: #D5824A;
    border: none;
}

.button-Sul{
    background: #B8645F;
    border: none;
}

.button-Centro-Oeste{
    background: #35468A;
    border: none;
}

.button-RO,.button-AC, .button-AM, .button-PA, .button-RO, .button-RR, .button-AP, .button-TO{
    background: #459788;
    border: none;
}

.button-MA, .button-PI, .button-CE, .button-RN, .button-PB, .button-PE, .button-AL, .button-SE, .button-BA{
    background: #246A85;
    border: none;
}

.button-DF, .button-GO, .button-MT, .button-MS{
    background: #35468A;
    border: none;
}

.button-MG, .button-RJ, .button-ES, .button-SP{
    background: #D5824A;
    border: none;
}

.button-PR, .button-RS, .button-SC{
    background: #B8645F;
    border: none;
}

.button-RO:active, .button-AC:active, .button-AM:active, .button-PA:active, .button-RO:active, .button-RR:active, .button-AP:active, .button-TO:active{
    background: #1C2226;
}

.button-MA:active, .button-PI:active, .button-CE:active, .button-RN:active, .button-PB:active, .button-PE:active, .button-AL:active, .button-SE:active, .button-BA:active{
    background: #1C2226;
}

.button-DF:active, .button-GO:active, .button-MT:active, .button-MS:active{
    background: #1C2226;
}

.button-MG:active, .button-RJ:active, .button-ES:active, .button-SP:active{
    background: #1C2226;
}

.button-PR:active, .button-RS:active, .button-SC:active{
    background: #1C2226;
}

.button-RO:visited, .button-AC:visited, .button-AM:visited, .button-PA:visited, .button-RO:visited, .button-RR:visited, .button-AP:visited, .button-TO:visited{
    background: #1C2226;
}

.button-MA:visited, .button-PI:visited, .button-CE:visited, .button-RN:visited, .button-PB:visited, .button-PE:visited, .button-AL:visited, .button-SE:visited, .button-BA:visited{
    background: #1C2226;
}

.button-DF:visited, .button-GO:visited, .button-MT:visited, .button-MS:visited{
    background: #1C2226;
}

.button-MG:visited, .button-RJ:visited, .button-ES:visited, .button-SP:visited{
    background: #1C2226;
}

.button-PR:visited, .button-RS:visited, .button-SC:visited{
    background: #1C2226;
}

.button-RO:focus, .button-AC:focus, .button-AM:focus, .button-PA:focus, .button-RO:focus, .button-RR:focus, .button-AP:focus, .button-TO:focus{
    background: #1C2226;
}

.button-MA:focus, .button-PI:focus, .button-CE:focus, .button-RN:focus, .button-PB:focus, .button-PE:focus, .button-AL:focus, .button-SE:focus, .button-BA:focus{
    background: #1C2226;
}

.button-DF:focus, .button-GO:focus, .button-MT:focus, .button-MS:focus{
    background: #1C2226;
}

.button-MG:focus, .button-RJ:focus, .button-ES:focus, .button-SP:focus{
    background: #1C2226;
}

.button-PR:focus, .button-RS:focus, .button-SC:focus{
    background: #1C2226;
}

.button-RO:hover, .button-AC:hover, .button-AM:hover, .button-PA:hover, .button-RO:hover, .button-RR:hover, .button-AP:hover, .button-TO:hover{
    background: #1F252A;
}

.button-MA:hover, .button-PI:hover, .button-CE:hover, .button-RN:hover, .button-PB:hover, .button-PE:hover, .button-AL:hover, .button-SE:hover, .button-BA:hover{
    background: #1F252A;
}

.button-DF:hover, .button-GO:hover, .button-MT:hover, .button-MS:hover{
    background: #1F252A;
}

.button-MG:hover, .button-RJ:hover, .button-ES:hover, .button-SP:hover{
    background: #1F252A;
}

.button-PR:hover, .button-RS:hover, .button-SC:hover{
    background: #1F252A;
}
