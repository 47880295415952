.buttongroup{
    align-items: center;
    padding: 20px 20px 20px 20px;
}

.equivalencia {
    padding-top: 50px;
}

.cardGroupMaterial{
    align-items: center;
    justify-content: center;
    margin: auto;}

.cardVolumeMaterial{
    margin: auto;
    margin: auto;
}

.textCardMaterial{
    text-align: left;
    font-size: 30px;
    font-weight: 400;
    color: #1F252A;
    padding: 30px;
    float: left;
    padding-left: 80px;
}

.cardMaterialsType{
    padding: 0;
    margin: 0;
    margin-right: 30px;
    margin-top: 15px;
}

@media screen and (max-width: 600px) {
    .textCardMaterial{
        font-size: 25px !important;
        text-align: center;
        margin: 20px;
        padding: 0;
    }
    .cardMaterialsType{
        margin: 0 !important;
        margin-top: 15px !important;
    }
    .cardVolumeMaterial{
        font-size: 10px !important;
    }
}